import { useState } from 'react';
import { FormControl, Select, MenuItem } from '@mui/material';
import i18n from 'i18n';

const ChangeLanguage = () => {
    const [value, setValue] = useState(i18n.language);
    const changeLanguage = (event) => {
        setValue(event.target.value);
        i18n.changeLanguage(event.target.value);
    };

    return (
        <FormControl sx={{ m: 1, minWidth: 100 }}>
            <Select id="select-language" value={value} onChange={changeLanguage}>
                <MenuItem value={'fr'}>Français</MenuItem>
                <MenuItem value={'en'}>English</MenuItem>
            </Select>
        </FormControl>
    );
};

export default ChangeLanguage;
