
import i18n from "i18next"
import { initReactI18next } from "react-i18next"
    
import LanguageDetector from "i18next-browser-languagedetector"
    
import { currentLanguage } from "./utils/currentLanguage"
    
import languageEN from "./i18n/en.json"
import languageFR from "./i18n/fr.json"
    
const options = {
     order: ["querystring", "navigator"],
    lookupQuerystring: "lng"
}
    
i18n
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
    detection: options,
    resources: {
        en: languageEN,
        fr: languageFR
    },
    lng: currentLanguage ? currentLanguage : "fr",
    fallbackLng: "fr",
    supportedLngs: ["fr", "en"],
    interpolation: {
        escapeValue: false // react already safes from xss
    }
})
export default i18n

    

