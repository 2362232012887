import { useRef, useState ,useEffect} from 'react';
import { useTranslation } from 'react-i18next';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Badge,
    Box,
    ClickAwayListener,
    // Divider,
    IconButton,
    List,
    // ListItemButton,
    // ListItemAvatar,
    // ListItemText,
    // ListItemSecondaryAction,
    Paper,
    Popper,
    Button ,
    useMediaQuery
} from '@mui/material';

// project import
import MainCard from 'components/MainCard';
import Transitions from 'components/@extended/Transitions';

// assets
import { BellOutlined, CloseOutlined, } from '@ant-design/icons';

// sx styles
const avatarSX = {
    width: 36,
    height: 36,
    fontSize: '1rem'
};

const actionSX = {
    mt: '6px',
    ml: 1,
    top: 'auto',
    right: 'auto',
    alignSelf: 'flex-start',

    transform: 'none'
};

// ==============================|| HEADER CONTENT - NOTIFICATION ||============================== //
import axios from "axios";
import url from "../../../../store/Api";
const Notification = () => {

    const { t } = useTranslation();
    
    const [BaseDown, setBaseDown] = useState(null);
    const [notifi, setNotifi] = useState("");
    const [FactureAttente, setFactureAttente] = useState(null);
    const [QweebyBase, setQweebyBase] = useState("");

    useEffect(() => {



        //date debut 
        axios.get(url + 'BaseDown.php')
            .then(res => {
                setBaseDown(res.data);
            });
        axios.get(url + 'FactureAttente.php')
            .then(res => {

                setFactureAttente(res.data);
            });
  
            const interval = setInterval(() => {
                axios.get(url + "seviceStatus/QweebyBase.php")
                    .then(res => {
                        setQweebyBase(res.data);

    
    
                    });
            }, 1000);
            return () => clearInterval(interval);
        });





    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
        setNotifi("");
    };
       const mail = () => {
        
        axios.get(url + 'mail.php').then(re=>{
            if(re.data=="Échec de l'envoi de l'email..."){
                setNotifi("Erreur d'envoyé le mail ...");
            }else{
                setNotifi("Email envoyé avec succès ..."); 
            }
           // setNotifi("Email envoyé avec succès ...");
           // localStorage.setItem("base","erreur");
           }).catch(erreur=>{
            setNotifi("Erreur d'envoyé le mail ...");
           })
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

const convertSeconds = given_seconds => {
let dateObj = new Date(given_seconds * 1000);
let hours = dateObj.getUTCHours();
let minutes = dateObj.getUTCMinutes();
let seconds = dateObj.getSeconds();

return hours.toString().padStart(2, '0')+"h" + ':' + 
    minutes.toString().padStart(2, '0')+"m" + ':' + 
    seconds.toString().padStart(2, '0')+"s"
}
    const iconBackColorOpen = 'grey.300';
    const iconBackColor = 'grey.100';

    return (
        <Box sx={{ flexShrink: 0, ml: 0.75 }}>
            <IconButton
                disableRipple
                color="secondary"
                sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor }}
                aria-label="open profile"
                ref={anchorRef}
                aria-controls={open ? 'profile-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
 {QweebyBase == "success"? <Badge>
                    <BellOutlined />
                </Badge> :
                 <Badge badgeContent={' '} color="error" >
                    <BellOutlined />
                </Badge>}
            </IconButton>

          
            <Popper
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? -5 : 0, 9]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions type="fade" in={open} {...TransitionProps}>
                        <Paper
                            sx={{
                                boxShadow: theme.customShadows.z1,
                                width: '100%',
                                minWidth: 285,
                                maxWidth: 420,
                                [theme.breakpoints.down('md')]: {
                                    maxWidth: 285
                                }
                            }}
                        >
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard
                                    title={t("DATABASE_STATUS")}
                                    elevation={0}
                                    border={false}
                                    content={false}
                                    secondary={
                                        <IconButton size="small" onClick={handleToggle}>
                                            <CloseOutlined />
                                        </IconButton>
                                    }
                                >
                                    <List
                                        component="nav"
                                        sx={{
                                            p: 0,
                                            '& .MuiListItemButton-root': {
                                                py: 0.5,
                                                '& .MuiAvatar-root': avatarSX,
                                                '& .MuiListItemSecondaryAction-root': { ...actionSX, position: 'relative' }
                                            }
                                        }}
                                    >

                            {QweebyBase!="success"? <>
                            
                            <h5 style={{ color: 'red',textAlign: 'center' }}>QweebyBase is down since {convertSeconds(BaseDown)} seconds.</h5>
                                       <h5 style={{ textAlign: 'center' }}>{FactureAttente} fichiers en attente.</h5>
                                       
                            
                             </>:<></>
}

                                     
                                       
                                       
                                       
                                       <h5 style={{color: 'green', textAlign: 'center' }}>{notifi} </h5>
                                       <Box style={{ textAlign: 'center' }}><Button variant="contained" onClick={mail}>{t("NOTIFY")}</Button></Box>

                                    </List>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>










        </Box>
    );
};

export default Notification;
