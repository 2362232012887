
import {
    Button,
    Divider,
    Grid,
    Typography
} from '@mui/material';
import Azure  from 'assets/images/icons/m.png'; 
import { Formik } from 'formik';
import AnimateButton from 'components/@extended/AnimateButton';
import {  useMsal } from "@azure/msal-react";
import { useTranslation } from 'react-i18next';
// import { InteractionType } from "@azure/msal-browser";

import { loginRequest } from 'authConfig';
const AuthLogin = () => {
    const { instance } = useMsal();
    const { t } = useTranslation();
    const handleLogin = () => {
        localStorage.setItem("base","succes");
        instance.loginPopup(loginRequest)
            .catch((error) => console.log(error))
    }
    return (
        <>
            <Formik
            >
                        <Grid container spacing={3}>
                              <Grid item xs={12}>
                                <Divider>
                                    <Typography variant="caption"> {t("LOGIN_WITH")}</Typography>
                                </Divider>
                            </Grid>
                            <Grid item xs={12}>
                                <AnimateButton>
                                    <Button
                                        disableElevation
                                        // disabled={isSubmitting}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        startIcon={<img src={Azure} alt="Google" width={20} />}
                                        variant="outlined"
                                        color="secondary"
                                        onClick={handleLogin}
                                      
                                    >
                                        Microsoft
                                    </Button>
                                </AnimateButton>
                            </Grid>
                        </Grid>
            </Formik>
        </>
    );
};

export default AuthLogin;
