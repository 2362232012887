// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';
import { MsalProvider } from "@azure/msal-react";
import Login from 'pages/authentication/Login';
// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //
import { AuthenticatedTemplate, UnauthenticatedTemplate  } from "@azure/msal-react";

const App = ({ instance }) => (
    <ThemeCustomization>
        <ScrollTop >
        <MsalProvider instance={instance}>
        <AuthenticatedTemplate>
         <Routes /> 
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
        <Login></Login>
        </UnauthenticatedTemplate>
     
      </MsalProvider>
            
        </ScrollTop>
    </ThemeCustomization>
);

export default App;
