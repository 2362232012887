import { useState } from 'react';
import { Box, Typography } from '@mui/material';

// project import
import NavGroup from './NavGroup';

import { useTranslation } from 'react-i18next';

import { DashboardOutlined, FileTextOutlined, NotificationOutlined, AlertOutlined, FileUnknownOutlined } from '@ant-design/icons';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
    const { t } = useTranslation();

    const items = [
        {
            id: 'group-dashboard',
            title: t('DASHBOARD'),
            type: 'group',
            children: [
                {
                    id: 'dashboard',
                    title: 'QweebyBase',
                    type: 'item',
                    url: '../',
                    icon: DashboardOutlined,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'ferlog',
            title: t('LOGS'),
            type: 'group',
            children: [
                {
                    id: 'ferlog',
                    title: t('LOGS'),
                    type: 'item',
                    url: '/ferlog',
                    icon: FileTextOutlined,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'serv',
            title: t('SERVICE'),
            type: 'group',
            children: [
                {
                    id: 'service',
                    title: t('SERVICE'),
                    type: 'item',
                    url: '/service',
                    icon: NotificationOutlined,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'perf',
            title: t('PERFORMANCE'),
            type: 'group',
            children: [
                {
                    id: 'perfermance',
                    title: t('PERFORMANCE'),
                    type: 'item',
                    url: '/perfermance',
                    icon: AlertOutlined,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'file',
            title: t('MENU_FILES'),
            type: 'group',
            children: [
                {
                    id: 'file',
                    title: t('MENU_FILES'),
                    type: 'item',
                    url: '/file',
                    icon: FileUnknownOutlined,
                    breadcrumbs: false
                }
            ]
        }
    ];
    const navGroups = items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Fix - Navigation Group
                    </Typography>
                );
        }
    });

    return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
};

export default Navigation;
